/**
 * @file Defines units
 */

enum FileSizeUnit {
  MB,
  GB,
}

export { FileSizeUnit }
